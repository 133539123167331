<template>
    <header :class="divClass" class="header-section v1">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <nav class="navbar navbar-expand-lg">
                        <div class="container header-navbar-container">
                            <a class="navbar-brand header-logo" href="/">
                                <img src="../assets/logo2.png" alt="header-logo"
                                    style="    width: 70%;
    height: auto;
    margin-left: -32px;" class="logo-light">
                                
                            </a>
                            <a id="nav-expander" class="nav-expander bar" href="#" @click.prevent="toggleMenu">
                                <img src="https://iqtester.org/assets/images/icons/menu.svg" alt="menu">
                                <img src="https://iqtester.org/assets/images/icons/menu-close.svg" alt="menu">
                            </a>
                            <div class="collapse navbar-collapse header-navbar-content" id="navbarSupportedContent">
                                <ul class="navbar-nav main-menu" >
                                    <li class="nav-item home-nav"></li>
                                    <li class="nav-item"><a class="nav-link" href="/">Home</a></li>
                                    <li class="nav-item"><a class="nav-link" style="width:80px;" href="/about">About
                                            Us</a></li>
                                    <li class="nav-item"><a class="nav-link" href="/blog">Blogs</a></li>
                                    <li class="nav-item"><a class="nav-link" href="/faq">FAQ</a></li>
                                    <li class="nav-item"><a class="nav-link" style="width:110px;"
                                            href="/contact">Contact us</a></li>
                                </ul>
                                <ul class="header-extra">
                                    <li><a style="width:190px;" href="/iqtest" class="bg-white-btn">
                                            <span class="btn-inner">
                                                <span class="btn-normal-text">Start IQ test</span>
                                                <span class="btn-hover-text">Start IQ test</span>
                                            </span>
                                        </a></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>

    <!-- Mobile Navigation -->
    <nav class="right_menu_togle mobile-navbar-menu" :class="{ 'active': isMenuOpen }" id="mobile-navbar-menu">
        <ul class="nav-menu" style="padding-left: 0">
            <a href="/" class="hash">
            <li class="hash-has-sub" style="    padding-left: 25px;
    height: 45px;
    padding-top: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                Home
            </li>
        </a>
        <a href="/about" class="hash">
            <li class="hash-has-sub" style="    padding-left: 25px;
    height: 45px;
    padding-top: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                About Us
            </li>
        </a>
        <a href="/blog" class="hash">
            <li class="hash-has-sub" style="    padding-left: 25px;
    height: 45px;
    padding-top: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                Blog
            </li>
            </a>
            <a href="/faq" class="hash">
            <li class="hash-has-sub" style="    padding-left: 25px;
    height: 45px;
    padding-top: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                FAQ
            </li>
            </a>
            <a href="/contact" class="hash">
            <li class="hash-has-sub" style="    padding-left: 25px;
    height: 45px;
    padding-top: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                Contact Us
            </li>
            </a>
        </ul>
        <ul class="nav-buttons">
            <li style="padding: 10px 45px;
    background-color: #faaa00 !important;
    border-radius: 100px;
    color: #ffffff;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    z-index: 0;
    font-weight: 700;
    margin-top: 10px;" class="hash-has-sub">
                <a href="/iqtest" class="bg-blue-btn hash">
                    <span class="btn-inner">
                        <span class="btn-normal-text">Start IQ Test</span>
                        
                    </span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        data() {
            return {
                isMenuOpen: false // To track the menu toggle state
            };
        },
        computed: {
            divClass() {
                // Get the current path from the route
                const path = this.$route.path;

                // If the path is just the root or empty (like localhost:8080/ or https://dev.iqspark.org/), return 'red'
                if (path === '/' || path === '') {
                    return 'red-background';
                }

                // Otherwise, return 'blue'
                return 'blue-background';
            }
        },
        methods: {
            toggleMenu() {
                // Toggle the mobile menu visibility
                this.isMenuOpen = !this.isMenuOpen;
            }
        }
    }
</script>
<style scoped>
 @font-face {
    font-family: BalooBhai2;
    src: url("../assets/baloo-bhai-2/BalooBhai2-Regular.ttf");
  }

  *{
    font-family: BalooBhai2 !important;
  }
    #mobile-navbar-menu {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        background-color: white;
        height: 60%;
        transition: transform 0.3s ease-in-out;
        transform: translateY(-100%);
    }

    #mobile-navbar-menu.active {
        display: block !important;
    transform: translateY(24%) !important;
    padding-top: 20px !important;
    height: 340px !important;
    z-index: 99999;
    margin-top: -34px;
    }

    .nav-expander {
        cursor: pointer;
    }

    @media screen and (max-width: 992px) {
        .red-background {
            background-color: #041e35 !important;

        }

        .nav-expander {
            filter: brightness(0) invert(1) !important;
        }
    }

    .red-background {
        background-color: transparent;
    }

    .blue-background {
        background-color: #041e35 !important;
    }

    /*! CSS Used from: https://dev.iqspark.org/css/app.0362521e.css */
    h5 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    h5 {
        font-size: 1.25rem;
    }

    @media (min-width:768px) {
        .col-md-12 {
            flex: 0 0 auto;
            width: 100%;
        }
    }

    .navbar-brand {
        padding-top: var(--bs-navbar-brand-padding-y);
        padding-bottom: var(--bs-navbar-brand-padding-y);
        margin-right: var(--bs-navbar-brand-margin-end);
        font-size: var(--bs-navbar-brand-font-size);
        color: var(--bs-navbar-brand-color);
        text-decoration: none;
        white-space: nowrap;
    }

    .navbar-brand:focus,
    .navbar-brand:hover {
        color: var(--bs-navbar-brand-hover-color);
    }

    .mt-3 {
        margin-top: 1rem !important;
    }

    h5 {
        font-family: Montserrat Alternates, sans-serif;
        font-weight: 700;
        letter-spacing: 0;
        color: #111;
        line-height: 1.46;
    }

    h5 {
        font-size: 18px;
        font-weight: 500;
    }

    .wt-700 {
        font-weight: 700 !important;
    }

    .col-md-12 {
        padding: 0 15px !important;
    }

    .header-section.v1 .logo-dark {
        display: none;
    }

    .header-section.v1 .main-menu .submenu li a {
        color: #444;
    }

    .header-section.v1 .main-menu .submenu li a:hover {
        color: #111;
    }

    .header-section .header-logo {
        padding: 0 !important;
        margin-right: 0;
    }

    .nav-item.home-nav {
        position: inherit !important;
    }

    .mega-menu {
        padding-top: 30px;
        background: transparent;
        top: 45px;
        left: 0;
        width: 100%;
        transition: .5s;
        position: absolute;
        transform-origin: 50% -30px;
        z-index: 100;
        opacity: 0;
        display: none;
        transform: rotateX(-15deg) translateZ(0);
    }

    .mega-menu-card {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, .05);
        box-shadow: 0 5px 20px rgba(0, 0, 0, .07);
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
    }

    .mega-menu-card .mega-menu-column {
        width: 25%;
        border-right: 1px solid rgba(0, 0, 0, .1);
        padding: 21px 40px 40px 40px;
        min-height: 450px;
        background: #fff;
        position: relative;
    }

    .mega-menu-card .mega-menu-column:nth-last-child(2) {
        border: none;
    }

    .mega-menu-card .mega-menu-column:last-child {
        border: none;
        background: #e3e9f7;
    }

    .mega-menu-card .mega-menu-column:last-child:after {
        content: "";
        height: 77px;
        width: 77px;
        background: linear-gradient(180deg, rgba(5, 85, 255, 0), rgba(5, 85, 255, .15));
        right: 20px;
        bottom: 70px;
        position: absolute;
        border-radius: 50%;
        z-index: 0;
    }

    .mega-menu-card .mega-menu-column:last-child:before {
        content: "";
        height: 137px;
        width: 137px;
        background: linear-gradient(180deg, rgba(5, 85, 255, .15), rgba(5, 85, 255, 0));
        top: 30px;
        left: 20px;
        position: absolute;
        border-radius: 50%;
        z-index: 0;
    }

    .mega-menu-list {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .mega-menu-list li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-height: 56px;
    }

    .mega-menu-list li a {
        font-family: DM Sans, sans-serif;
        font-weight: 500;
        font-size: 15px;
        line-height: 50px;
        color: #444;
        transition: .3s;
    }

    .mega-menu-img {
        background: #405364;
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        z-index: 1;
    }

    .mega-menu-img img,
    .mega-menu-tabcontent {
        height: 100%;
        width: 100%;
    }

    .mega-menu-tabcontent {
        position: absolute;
        top: 0;
        left: 0;
        padding: 50px 40px;
    }

    .megaMenutabcontent {
        display: none;
        animation: fadeEffect .4s;
    }

    .more-demo-card {
        background: #faeef1;
        border-radius: 10px;
        padding: 17px 20px;
        position: relative;
        margin-top: 25px;
    }

    .more-demo-card p {
        color: #111;
        position: relative;
        z-index: 1;
        margin-bottom: 0;
    }

    .more-demo-card:after {
        content: "";
        position: absolute;
        top: 12px;
        left: 8px;
        height: 33px;
        width: 33px;
        border-radius: 50%;
        background: linear-gradient(180deg, rgba(255, 183, 202, .5), rgba(250, 238, 241, .5));
        z-index: 0;
    }

    .more-demo-card img {
        position: absolute;
        top: 21px;
        right: 20px;
    }

    .bg-white-btn {
        padding: 12px 39px;
        background-color: #faaa00;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        color: #111 !important;
        transition: .3s;
        position: relative;
        overflow: hidden;
        font-weight: 700;
    }

    .bg-white-btn .btn-inner {
        display: inline-block;
    }

    .bg-white-btn span {
        position: relative;
        z-index: 11;
        transition: opacity .6s, transform .8s;
        transition-timing-function: cubic-bezier(.15, .85, .31, 1);
        color: #fff;
    }

    .bg-white-btn span:nth-child(2) {
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 80%);
        opacity: 0;
    }

    .bg-white-btn span {
        color: #111;
    }

    .bg-white-btn:focus .btn-inner,
    .bg-white-btn:hover .btn-inner {
        transform: translateY(-150%);
    }

    .bg-white-btn:focus span:nth-child(2),
    .bg-white-btn:hover span:nth-child(2) {
        transform: translate(-50%, 100%);
        opacity: 1;
    }

    .bg-white-btn:focus span .btn-normal-text,
    .bg-white-btn:hover span .btn-normal-text {
        opacity: 0;
    }

    @media (max-width:767px) {
        .header-logo {
            display: none;
        }
    }

    @media screen and (max-width:1399px) {

        .header-extra,
        .main-menu {
            gap: 20px;
        }
    }

    @media screen and (max-width:991px) {
        .nav-expander {
            display: block;
        }

        .header-navbar-content {
            position: fixed;
            top: 0;
            left: 0;
            width: 320px;
            height: 100%;
            background-color: #000;
            padding: 30px;
            flex-direction: column;
            align-items: flex-start !important;
            justify-content: flex-start !important;
            transition: .3s;
            transform: translateX(-155%);
            height: 100vh;
        }

        .main-menu {
            flex-direction: column;
            align-items: flex-start;
        }

        .main-menu li {
            position: relative;
        }

        .main-menu li a {
            padding: 0;
        }

        .main-menu li:hover .dropdown-toggle:after {
            transform: rotate(0deg);
        }

        .main-menu .submenu,
        .main-menu li:hover .submenu {
            transform: translateY(10px);
        }

        .main-menu .submenu {
            display: none;
            position: unset;
            top: 100%;
            left: 0;
            width: 100%;
            background-color: transparent;
            border: none;
            box-shadow: none;
            border-top: 0;
            padding: 5px 0;
            transition: .3s;
            opacity: 1;
            visibility: visible;
        }

        .main-menu .submenu li a {
            padding: 10px 15px;
        }

        .header-extra {
            margin-top: 30px;
        }

        .header-section:not(.v6) {
            background: #fff;
            padding: 15px 0;
        }

        .header-section .logo-light {
            display: none;
        }

        .header-section .logo-dark {
            display: block !important;
        }

        .header-section.v1 .main-menu .submenu li a {
            color: #ffffffb2;
        }
    }

    @media screen and (max-width:575px) {
        .container {
            padding: 0 20px;
        }
    }

    @media screen and (max-width:480px) {
        .header-navbar-content {
            width: 250px;
        }
    }

    a {
        cursor: pointer;
    }

    a,
    a:focus,
    a:hover {
        text-decoration: none;
        color: inherit;
    }

    a:focus {
        outline: none;
        border: none;
    }

    .col-md-12 {
        padding: 0 15px !important;
    }

    @media screen and (max-width:991px) {
        .nav-expander {
            display: block;
        }

        .header-navbar-content {
            position: fixed;
            top: 0;
            left: 0;
            width: 320px;
            height: 100%;
            background-color: #000;
            padding: 30px;
            flex-direction: column;
            align-items: flex-start !important;
            justify-content: flex-start !important;
            transition: .3s;
            transform: translateX(-155%);
            height: 100vh;
        }

        .main-menu {
            flex-direction: column;
            align-items: flex-start;
        }

        .main-menu li {
            position: relative;
        }

        .main-menu li a {
            padding: 0;
        }

        .main-menu li:hover .dropdown-toggle:after {
            transform: rotate(0deg);
        }

        .main-menu .submenu,
        .main-menu li:hover .submenu {
            transform: translateY(10px);
        }

        .main-menu .submenu {
            display: none;
            position: unset;
            top: 100%;
            left: 0;
            width: 100%;
            background-color: transparent;
            border: none;
            box-shadow: none;
            border-top: 0;
            padding: 5px 0;
            transition: .3s;
            opacity: 1;
            visibility: visible;
        }

        .main-menu .submenu li a {
            padding: 10px 15px;
        }

        .header-extra {
            margin-top: 30px;
        }

        .header-section:not(.v6) {
            background: #fff;
            padding: 15px 0;
        }

        .header-section .logo-light {
            display: none;
        }
    }

    @media screen and (max-width:575px) {
        .container {
            padding: 0 20px;
        }
    }

    @media screen and (max-width:480px) {
        .header-navbar-content {
            width: 250px;
        }
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ul {
        padding-left: 2rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ul ul {
        margin-bottom: 0;
    }

    a {
        color: var(--bs-link-color);
        text-decoration: underline;
    }

    a:hover {
        color: var(--bs-link-hover-color);
    }

    img {
        vertical-align: middle;
    }

    .container {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x)*.5);
        padding-left: calc(var(--bs-gutter-x)*.5);
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width:576px) {
        .container {
            max-width: 540px;
        }
    }

    @media (min-width:768px) {
        .container {
            max-width: 720px;
        }
    }

    @media (min-width:992px) {
        .container {
            max-width: 960px;
        }
    }

    @media (min-width:1200px) {
        .container {
            max-width: 1140px;
        }
    }

    @media (min-width:1400px) {
        .container {
            max-width: 1320px;
        }
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(var(--bs-gutter-y)*-1);
        margin-right: calc(var(--bs-gutter-x)*-.5);
        margin-left: calc(var(--bs-gutter-x)*-.5);
    }

    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x)*.5);
        padding-left: calc(var(--bs-gutter-x)*.5);
        margin-top: var(--bs-gutter-y);
    }

    @media (min-width:768px) {
        .col-md-12 {
            flex: 0 0 auto;
            width: 100%;
        }
    }

    .collapse:not(.show) {
        display: none;
    }

    .dropdown-toggle {
        white-space: nowrap;
    }

    .dropdown-toggle:after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }

    .dropdown-toggle:empty:after {
        margin-left: 0;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
        clear: both;
        font-weight: 400;
        color: var(--bs-dropdown-link-color);
        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
    }

    .dropdown-item:focus,
    .dropdown-item:hover {
        color: var(--bs-dropdown-link-hover-color);
        background-color: var(--bs-dropdown-link-hover-bg);
    }

    .dropdown-item:active {
        color: var(--bs-dropdown-link-active-color);
        text-decoration: none;
        background-color: var(--bs-dropdown-link-active-bg);
    }

    .dropdown-item:disabled {
        color: var(--bs-dropdown-link-disabled-color);
        pointer-events: none;
        background-color: transparent;
    }

    .nav-link {
        display: block;
        padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
        font-size: var(--bs-nav-link-font-size);
        font-weight: var(--bs-nav-link-font-weight);
        color: var(--bs-nav-link-color);
        text-decoration: none;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    }

    @media (prefers-reduced-motion:reduce) {
        .nav-link {
            transition: none;
        }
    }

    .nav-link:focus,
    .nav-link:hover {
        color: var(--bs-nav-link-hover-color);
    }

    .navbar {
        --bs-navbar-padding-x: 0;
        --bs-navbar-padding-y: 0.5rem;
        --bs-navbar-color: rgba(0, 0, 0, .55);
        --bs-navbar-hover-color: rgba(0, 0, 0, .7);
        --bs-navbar-disabled-color: rgba(0, 0, 0, .3);
        --bs-navbar-active-color: rgba(0, 0, 0, .9);
        --bs-navbar-brand-padding-y: 0.3125rem;
        --bs-navbar-brand-margin-end: 1rem;
        --bs-navbar-brand-font-size: 1.25rem;
        --bs-navbar-brand-color: rgba(0, 0, 0, .9);
        --bs-navbar-brand-hover-color: rgba(0, 0, 0, .9);
        --bs-navbar-nav-link-padding-x: 0.5rem;
        --bs-navbar-toggler-padding-y: 0.25rem;
        --bs-navbar-toggler-padding-x: 0.75rem;
        --bs-navbar-toggler-font-size: 1.25rem;
        --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvgxmlns=%27http://www.w3.org/2000/svg%27viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27stroke-linecap=%27round%27stroke-miterlimit=%2710%27stroke-width=%272%27d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
        --bs-navbar-toggler-border-color: rgba(0, 0, 0, .1);
        --bs-navbar-toggler-border-radius: 0.375rem;
        --bs-navbar-toggler-focus-width: 0.25rem;
        --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
        position: relative;
        flex-wrap: wrap;
        padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
    }

    .navbar,
    .navbar>.container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar>.container {
        flex-wrap: inherit;
    }

    .navbar-nav {
        --bs-nav-link-padding-x: 0;
        --bs-nav-link-padding-y: 0.5rem;
        --bs-nav-link-color: var(--bs-navbar-color);
        --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
        --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    }

    @media (min-width:992px) {
        .navbar-expand-lg {
            flex-wrap: nowrap;
            justify-content: flex-start;
        }

        .navbar-expand-lg .navbar-nav {
            flex-direction: row;
        }

        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: var(--bs-navbar-nav-link-padding-x);
            padding-left: var(--bs-navbar-nav-link-padding-x);
        }

        .navbar-expand-lg .navbar-collapse {
            display: flex !important;
            flex-basis: auto;
        }
    }

    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    html * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :focus {
        outline: none;
    }

    img {
        max-width: 100%;
    }

    ::selection {
  background-color: #007bff; /* Use a blue color for the selection background */
  color: #fff; /* Use white for the selected text color */
}

    a {
        cursor: pointer;
    }

    a,
    a:focus,
    a:hover {
        text-decoration: none;
        color: inherit;
    }

    a:focus {
        outline: none;
        border: none;
    }

    @media (min-width:1400px) {
        .container {
            max-width: 1170px;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .row {
        margin: 0 -15px !important;
    }

    .col-md-12 {
        padding: 0 15px !important;
    }

    .header-section {
        position: absolute;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px 0;
    }

    .header-section .header-navbar-container {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        align-items: center;
        gap: 60px;
    }

    .header-section .header-navbar-content {
        display: flex;
        align-items: center;
        gap: 30px;
        justify-content: space-between;
    }

    .header-section .navbar {
        padding: 0;
    }

    .main-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
    }

    .main-menu ul {
        list-style: none;
    }

    .main-menu li {
        position: relative;
    }

    .main-menu li:hover .dropdown-toggle:after {
        transform: rotate(180deg);
    }

    .main-menu li:hover .submenu {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        width: 250px;
    }

    .main-menu li:hover .submenu li a {
        line-height: 333%;
    }

    .main-menu li a {
        color: #fff;
        transition: .3s;
        padding: 15px 0;
        font-weight: 500;
    }

    .main-menu li a:focus,
    .main-menu li a:hover {
        color: #ffffffb2;
    }

    .main-menu .dropdown-toggle:after {
        vertical-align: 0;
        border: 0;
        content: url(https://iqtester.org/assets/images/icons/dropdown-toggle.svg);
        transition: .3s;
        filter: brightness(0) invert(1);
        display: none;
    }

    .main-menu .submenu {
        position: absolute;
        top: 120%;
        left: 0;
        width: 180px;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, .08);
        box-shadow: 0 10px 15px rgba(0, 0, 0, .05);
        border-radius: 10px;
        padding: 13px 24px;
        transform: translateY(10px);
        transition: .3s;
        opacity: 0;
        visibility: hidden;
    }

    .main-menu .submenu li a {
        line-height: 36px;
        padding: 0 !important;
    }

    .main-menu .submenu li a:hover {
        color: #0095ff;
    }

    .header-extra {
        display: flex;
        align-items: center;
        gap: 30px;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .header-extra li a {
        color: #fff;
        transition: .3s;
    }

    .header-extra li a:hover {
        color: #ffffffb2;
    }

    .nav-expander {
        display: none;
    }

    .nav-expander img:first-child {
        display: block;
    }

    .nav-expander img:nth-child(2) {
        display: none;
    }

    .container {
        position: relative;
        z-index: 2;
    }

    @media screen and (max-width:1399px) {

        .header-extra,
        .main-menu {
            gap: 20px;
        }
    }

    @media screen and (min-width:991px) and (max-width:1200px) {

        .header-section .header-extra li a,
        .header-section .main-menu li a {
            font-size: 13px;
        }

        .header-extra,
        .main-menu {
            gap: 25px;
        }
    }

    @media screen and (max-width:991px) {
        .nav-expander {
            display: block;
        }

        .header-navbar-content {
            position: fixed;
            top: 0;
            left: 0;
            width: 320px;
            height: 100%;
            background-color: #000;
            padding: 30px;
            flex-direction: column;
            align-items: flex-start !important;
            justify-content: flex-start !important;
            transition: .3s;
            transform: translateX(-155%);
            height: 100vh;
        }

        .main-menu {
            flex-direction: column;
            align-items: flex-start;
        }

        .main-menu li {
            position: relative;
        }

        .main-menu li a {
            padding: 0;
        }

        .main-menu li:hover .dropdown-toggle:after {
            transform: rotate(0deg);
        }

        .main-menu .submenu,
        .main-menu li:hover .submenu {
            transform: translateY(10px);
        }

        .main-menu .submenu {
            display: none;
            position: unset;
            top: 100%;
            left: 0;
            width: 100%;
            background-color: transparent;
            border: none;
            box-shadow: none;
            border-top: 0;
            padding: 5px 0;
            transition: .3s;
            opacity: 1;
            visibility: visible;
        }

        .main-menu .submenu li a {
            padding: 10px 15px;
        }

        .header-extra {
            margin-top: 30px;
        }

        .header-section:not(.v6) {
            background: #fff;
            padding: 15px 0;
        }

        .header-section .logo-light {
            display: none;
        }
    }

    @media screen and (max-width:575px) {
        .container {
            padding: 0 20px;
        }
    }

    @media screen and (max-width:480px) {
        .header-navbar-content {
            width: 250px;
        }
    }

    /*! CSS Used keyframes */
    @keyframes fadeEffect {
        0% {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
</style>