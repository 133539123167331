<template>
  <Webheader />
  <section>
    <div id="primary" class="content-area primary">
      <main id="main" class="site-main">
        <article class="post-895 page type-page status-publish ast-article-single" id="post-895"
          itemtype="https://schema.org/CreativeWork" itemscope="itemscope">

          <header class="entry-header ast-no-thumbnail ast-no-title ast-header-without-markup">
          </header> <!-- .entry-header -->
          <div class="entry-content clear" itemprop="text">
            <div data-elementor-type="wp-page" data-elementor-id="895" class="elementor elementor-895"
              data-elementor-post-type="page">
              <div class="elementor-element elementor-element-de51030 e-flex e-con-boxed e-con e-parent e-lazyloaded media-content-margin"
                data-id="de51030" data-element_type="container">
                <div class="e-con-inner">
                  <div class="elementor-element elementor-element-0886e87 e-flex e-con-boxed e-con e-child"
                    data-id="0886e87" data-element_type="container">
                    <div class="e-con-inner">
                      <div class="elementor-element elementor-element-2faa527 elementor-widget elementor-widget-heading"
                        data-id="2faa527" data-element_type="widget" data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">How can we help you?</h2>
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-56d0118 elementor-widget elementor-widget-text-editor"
                        data-id="56d0118" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <p>Drop us a note here, and we’ll get back to you shortly.</p>
                          <p><em>If you don’t see a reply within a day or two, please check your mail spam box.</em></p>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-28bda67 elementor-button-align-start elementor-widget elementor-widget-form"
                        data-id="28bda67" data-element_type="widget"
                        data-settings="{&quot;step_next_label&quot;:&quot;Next&quot;,&quot;step_previous_label&quot;:&quot;Previous&quot;,&quot;button_width&quot;:&quot;100&quot;,&quot;step_type&quot;:&quot;number_text&quot;,&quot;step_icon_shape&quot;:&quot;circle&quot;}"
                        data-widget_type="form.default">
                        <div class="elementor-widget-container">
                          <form class="elementor-form" @submit.prevent="submitForm">
                            <input type="hidden" name="post_id" value="895">
                            <input type="hidden" name="form_id" value="28bda67">
                            <input type="hidden" name="referer_title" value="contact - iqspark.org">
                            <input type="hidden" name="queried_id" value="895">

                            <div class="elementor-form-fields-wrapper elementor-labels-above">
                              <div class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-50">
                                <input size="1" type="text" v-model="firstName" id="form-field-name"
                                  class="elementor-field elementor-size-sm elementor-field-textual" placeholder="First Name">
                              </div>
                              <div class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-field_d76396a elementor-col-50">
                                <input size="1" type="text" v-model="lastName" id="form-field-field_d76396a"
                                  class="elementor-field elementor-size-sm elementor-field-textual" placeholder="Last Name">
                              </div>
                              <div class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-field_5097c43 elementor-col-100">
                                <input size="1" type="email" v-model="email" id="form-field-field_5097c43"
                                  class="elementor-field elementor-size-sm elementor-field-textual" placeholder="Email Address">
                              </div>
                              <div class="elementor-field-type-textarea elementor-field-group elementor-column elementor-field-group-message elementor-col-100">
                                <textarea class="elementor-field-textual elementor-field elementor-size-sm" v-model="message"
                                  id="form-field-message" rows="5" placeholder="Enter Message or Comment"></textarea>
                              </div>
                              <div class="elementor-field-group elementor-column elementor-field-type-submit elementor-col-100 e-form__buttons">
                                <button style="background-color: #041e35;
    color: #fff;" type="submit" class="elementor-button elementor-size-sm">
                                  <span>
                                    <span class="elementor-button-text">Send Message</span>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </form>
                          <!-- Success message -->
                          <div v-if="successMessage" style="color: red;
                              margin-left: 3%;  margin-top: 15px;" class="success-message">
                            *{{ successMessage }}
                          </div>
                          <div v-if="contactError" style="color: red;
                              margin-left: 3%;    margin-top: 15px;" class="success-message">
                              {{contactError}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- .entry-content .clear -->
        </article><!-- #post-## -->
      </main><!-- #main -->
    </div>
  </section>
  <Footers />
</template>

<script>
import axios from 'axios';
import Webheader from "../components/Webheader.vue";
import Footers from "../components/Footer.vue";

export default {
  name: 'App',

  components: {
    Webheader,
    Footers
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      successMessage: '',
      contactError:''  // Message to show after successful email submission
    };
  },

  methods: {
    async submitForm() {
      if(this.firstName != "" &&  this.lastName != '' && 
            this.email != '' && 
            this.message != '' && this.firstName != null &&  this.lastName != null && 
            this.email != null && 
            this.message != null && this.firstName != undefined &&  this.lastName != undefined && 
            this.email != undefined && 
            this.message != undefined){
              this.contactError = ""
        try {
          const response = await axios.post('https://api.iqspark.org/public/api/contact', {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            comments: this.message
          });
          
          // If the request was successful
          if (response.status === 200) {
            this.successMessage = "Email sent successfully!";
            
            // Clear the form fields
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.message = '';
          }
        } catch (error) {
          console.error('Error sending the email:', error);
          this.successMessage = "There was an issue sending the email. Please try again later.";
        }
      }else{
        this.contactError = "Please fill all fields!"
      }
    }
  }
};
</script>
<style scoped>
@media screen and (max-width:500px){
    .media-content-margin{
      margin-top: 18px !important;
    }
}
 @font-face {
    font-family: BalooBhai2;
    src: url("../assets/baloo-bhai-2/BalooBhai2-Regular.ttf");
  }

  *{
    font-family: BalooBhai2 !important;
  }
  /*! CSS Used from: https://iqspark.org/wp-content/themes/astra/assets/css/minified/main.min.css?ver=4.8.2 ; media=all */
  @media all {

    h2,
    p,
    textarea {
      border: 0;
      font-size: 100%;
      font-style: inherit;
      font-weight: inherit;
      margin: 0;
      outline: 0;
      padding: 0;
      vertical-align: baseline;
    }

    main {
      display: block;
    }

    button,
    input,
    textarea {
      color: inherit;
      font: inherit;
      margin: 0;
    }

    button {
      overflow: visible;
    }

    button {
      text-transform: none;
    }

    button {
      -webkit-appearance: button;
      cursor: pointer;
    }

    input {
      line-height: normal;
    }

    textarea {
      overflow: auto;
    }

    h2 {
      clear: both;
    }

    .entry-content h2,
    h2 {
      color: #808285;
      font-size: 1.7em;
      line-height: 1.3;
    }

    *,
    :after,
    :before {
      box-sizing: inherit;
    }

    em {
      font-style: italic;
    }

    button,
    input,
    textarea {
      color: #808285;
      font-weight: 400;
      font-size: 100%;
      margin: 0;
      vertical-align: baseline;
    }

    button,
    input {
      line-height: normal;
    }

    button {
      border: 1px solid;
      border-color: var(--ast-border-color);
      border-radius: 2px;
      background: #e6e6e6;
      padding: .6em 1em .4em;
      color: #fff;
    }

    button:focus,
    button:hover {
      color: #fff;
      border-color: var(--ast-border-color);
    }

    button:active,
    button:focus {
      border-color: var(--ast-border-color);
      outline: 0;
    }

    input[type=email],
    input[type=text],
    textarea {
      color: #666;
      border: 1px solid var(--ast-border-color);
      border-radius: 2px;
      -webkit-appearance: none;
    }

    input[type=email]:focus,
    input[type=text]:focus,
    textarea:focus {
      color: #111;
    }

    textarea {
      padding-left: 3px;
      width: 100%;
    }

    ::selection {
  background-color: #007bff; /* Use a blue color for the selection background */
  color: #fff; /* Use white for the selected text color */
}

    .entry-content h2 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 1.75em;
    }

    input {
      line-height: 1;
    }

    button,
    textarea {
      line-height: 1.85714285714286;
    }

    #primary {
      width: 100%;
    }

    #primary {
      margin: 4em 0;
    }

    .entry-content>:last-child {
      margin-bottom: 0;
    }

    input[type=email],
    input[type=text],
    textarea {
      color: #666;
      padding: .75em;
      height: auto;
      border-width: 1px;
      border-style: solid;
      border-color: var(--ast-border-color);
      border-radius: 2px;
      background: var(--ast-comment-inputs-background);
      box-shadow: none;
      box-sizing: border-box;
      transition: all .2s linear;
    }

    input[type=email]:focus,
    input[type=text]:focus,
    textarea:focus {
      background-color: #fff;
      border-color: var(--ast-border-color);
      box-shadow: none;
    }

    textarea {
      width: 100%;
    }

    .page .entry-header {
      margin-bottom: 1.5em;
    }

    .ast-single-post .entry-header.ast-header-without-markup,
    .ast-single-post .entry-header.ast-no-title.ast-no-thumbnail {
      margin-bottom: 0;
    }

    .entry-header {
      margin-bottom: 1.2em;
      word-wrap: break-word;
    }

    .entry-content {
      word-wrap: break-word;
    }

    .entry-content p {
      margin-bottom: 1.6em;
    }

    .entry-content::after {
      display: table;
      clear: both;
      content: '';
    }
  }

  /*! CSS Used from: Embedded */
  button,
  input,
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-size: 1rem;
    line-height: var(--ast-body-line-height, 1.65em);
  }

  h2,
  .entry-content h2 {
    font-weight: 600;
  }

  h2,
  .entry-content h2 {
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 1.3em;
  }
  ::selection {
  background-color: #007bff; /* Use a blue color for the selection background */
  color: #fff; /* Use white for the selected text color */
}

  h2,
  .entry-content h2 {
    color: var(--ast-global-color-3);
  }

  input:focus,
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    border-color: var(--ast-global-color-0);
  }

  input:focus,
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    border-style: dotted;
    border-color: inherit;
    border-width: thin;
  }

  input {
    outline: none;
  }

  .ast-page-builder-template .site .site-content #primary {
    padding: 0;
    margin: 0;
  }

  .ast-page-builder-template .entry-header.ast-no-title.ast-no-thumbnail {
    margin-top: 0;
  }

  .ast-page-builder-template .entry-header.ast-header-without-markup {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ast-page-builder-template .entry-header {
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    padding: 12px 16px;
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    color: var(--ast-form-input-text, #475569);
  }

  input[type="text"],
  input[type="email"] {
    height: 40px;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    border-color: #046BD2;
    box-shadow: none;
    outline: none;
    color: var(--ast-form-input-focus-text, #475569);
  }

  button {
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  }

  ::placeholder {
    color: var(--ast-form-field-color, #9CA3AF);
  }

  @media (max-width:921px) {
    #primary {
      padding: 1.5em 0;
      margin: 0;
    }
  }

  .elementor-widget-heading h2.elementor-heading-title {
    line-height: 1.3em;
  }

  button {
    border-style: solid;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
    color: #ffffff;
    border-color: var(--ast-global-color-0);
    background-color: var(--ast-global-color-0);
    padding-top: 15px;
    padding-right: 30px;
    padding-bottom: 15px;
    padding-left: 30px;
    font-family: inherit;
    font-weight: 500;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1em;
  }

  button:focus,
  button:hover {
    color: #ffffff;
    background-color: var(--ast-global-color-1);
    border-color: var(--ast-global-color-1);
  }

  @media (max-width:921px) {
    button {
      padding-top: 14px;
      padding-right: 28px;
      padding-bottom: 14px;
      padding-left: 28px;
    }
  }

  @media (max-width:544px) {
    button {
      padding-top: 12px;
      padding-right: 24px;
      padding-bottom: 12px;
      padding-left: 24px;
    }
  }

  @media (max-width:921px) {

    h2,
    .entry-content h2 {
      font-size: 25px;
    }
  }

  @media (max-width:544px) {

    h2,
    .entry-content h2 {
      font-size: 25px;
    }
  }

  .ast-page-builder-template .entry-header {
    padding-left: 0;
  }

  h2,
  .entry-content h2 {
    color: var(--ast-global-color-2);
  }

  .elementor-widget-heading .elementor-heading-title {
    margin: 0;
  }

  /*! CSS Used from: https://iqspark.org/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.24.5 ; media=all */
  @media all {

    .elementor *,
    .elementor :after,
    .elementor :before {
      box-sizing: border-box;
    }

    .elementor-element {
      --flex-direction: initial;
      --flex-wrap: initial;
      --justify-content: initial;
      --align-items: initial;
      --align-content: initial;
      --gap: initial;
      --flex-basis: initial;
      --flex-grow: initial;
      --flex-shrink: initial;
      --order: initial;
      --align-self: initial;
      flex-basis: var(--flex-basis);
      flex-grow: var(--flex-grow);
      flex-shrink: var(--flex-shrink);
      order: var(--order);
      align-self: var(--align-self);
    }

    .elementor-element:where(.e-con-full, .elementor-widget) {
      flex-direction: var(--flex-direction);
      flex-wrap: var(--flex-wrap);
      justify-content: var(--justify-content);
      align-items: var(--align-items);
      align-content: var(--align-content);
      gap: var(--gap);
    }

    .elementor-widget {
      position: relative;
    }

    .elementor-widget:not(:last-child) {
      margin-bottom: 20px;
    }

    .elementor-column {
      position: relative;
      min-height: 1px;
      display: flex;
    }

    @media (min-width:768px) {
      .elementor-column.elementor-col-50 {
        width: 50%;
      }

      .elementor-column.elementor-col-100 {
        width: 100%;
      }
    }

    @media (max-width:767px) {
      .elementor-column {
        width: 100%;
      }
    }

    .e-con {
      --border-radius: 0;
      --border-top-width: 0px;
      --border-right-width: 0px;
      --border-bottom-width: 0px;
      --border-left-width: 0px;
      --border-style: initial;
      --border-color: initial;
      --container-widget-width: 100%;
      --container-widget-height: initial;
      --container-widget-flex-grow: 0;
      --container-widget-align-self: initial;
      --content-width: min(100%, var(--container-max-width, 1140px));
      --width: 100%;
      --min-height: initial;
      --height: auto;
      --text-align: initial;
      --margin-top: 0px;
      --margin-right: 0px;
      --margin-bottom: 0px;
      --margin-left: 0px;
      --padding-top: var(--container-default-padding-top, 10px);
      --padding-right: var(--container-default-padding-right, 10px);
      --padding-bottom: var(--container-default-padding-bottom, 10px);
      --padding-left: var(--container-default-padding-left, 10px);
      --position: relative;
      --z-index: revert;
      --overflow: visible;
      --gap: var(--widgets-spacing, 20px);
      --overlay-mix-blend-mode: initial;
      --overlay-opacity: 1;
      --overlay-transition: 0.3s;
      --e-con-grid-template-columns: repeat(3, 1fr);
      --e-con-grid-template-rows: repeat(2, 1fr);
      position: var(--position);
      width: var(--width);
      min-width: 0;
      min-height: var(--min-height);
      height: var(--height);
      border-radius: var(--border-radius);
      z-index: var(--z-index);
      overflow: var(--overflow);
      transition: background var(--background-transition, .3s), border var(--border-transition, .3s), box-shadow var(--border-transition, .3s), transform var(--e-con-transform-transition-duration, .4s);
      --flex-wrap-mobile: wrap;
      margin-block-start: var(--margin-block-start);
      margin-inline-end: var(--margin-inline-end);
      margin-block-end: var(--margin-block-end);
      margin-inline-start: var(--margin-inline-start);
      padding-inline-start: var(--padding-inline-start);
      padding-inline-end: var(--padding-inline-end);
      --margin-block-start: var(--margin-top);
      --margin-block-end: var(--margin-bottom);
      --margin-inline-start: var(--margin-left);
      --margin-inline-end: var(--margin-right);
      --padding-inline-start: var(--padding-left);
      --padding-inline-end: var(--padding-right);
      --padding-block-start: var(--padding-top);
      --padding-block-end: var(--padding-bottom);
      --border-block-start-width: var(--border-top-width);
      --border-block-end-width: var(--border-bottom-width);
      --border-inline-start-width: var(--border-left-width);
      --border-inline-end-width: var(--border-right-width);
    }

    .e-con.e-flex {
      --flex-direction: column;
      --flex-basis: auto;
      --flex-grow: 0;
      --flex-shrink: 1;
      flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis);
    }

    .e-con>.e-con-inner {
      text-align: var(--text-align);
      padding-block-start: var(--padding-block-start);
      padding-block-end: var(--padding-block-end);
    }

    .e-con.e-flex>.e-con-inner {
      flex-direction: var(--flex-direction);
    }

    .e-con,
    .e-con>.e-con-inner {
      display: var(--display);
    }

    .e-con-boxed.e-flex {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: normal;
      align-items: normal;
      align-content: normal;
    }

    .e-con-boxed {
      text-align: initial;
      gap: initial;
    }

    .e-con.e-flex>.e-con-inner {
      flex-wrap: var(--flex-wrap);
      justify-content: var(--justify-content);
      align-items: var(--align-items);
      align-content: var(--align-content);
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 1;
      align-self: auto;
    }

    .e-con>.e-con-inner {
      gap: var(--gap);
      width: 100%;
      max-width: var(--content-width);
      margin: 0 auto;
      padding-inline-start: 0;
      padding-inline-end: 0;
      height: 100%;
    }

    :is(.elementor-section-wrap, [data-elementor-id])>.e-con {
      --margin-left: auto;
      --margin-right: auto;
      max-width: min(100%, var(--width));
    }

    .e-con .elementor-widget.elementor-widget {
      margin-block-end: 0;
    }

    .e-con:before {
      content: var(--background-overlay);
      display: block;
      position: absolute;
      mix-blend-mode: var(--overlay-mix-blend-mode);
      opacity: var(--overlay-opacity);
      transition: var(--overlay-transition, .3s);
      border-radius: var(--border-radius);
      border-style: var(--border-style);
      border-color: var(--border-color);
      border-block-start-width: var(--border-block-start-width);
      border-inline-end-width: var(--border-inline-end-width);
      border-block-end-width: var(--border-block-end-width);
      border-inline-start-width: var(--border-inline-start-width);
      top: calc(0px - var(--border-top-width));
      left: calc(0px - var(--border-left-width));
      width: max(100% + var(--border-left-width) + var(--border-right-width), 100%);
      height: max(100% + var(--border-top-width) + var(--border-bottom-width), 100%);
    }

    .e-con:before {
      transition: background var(--overlay-transition, .3s), border-radius var(--border-transition, .3s), opacity var(--overlay-transition, .3s);
    }

    .e-con .elementor-widget {
      min-width: 0;
    }

    .e-con>.e-con-inner>.elementor-widget>.elementor-widget-container {
      height: 100%;
    }

    .e-con.e-con>.e-con-inner>.elementor-widget {
      max-width: 100%;
    }

    @media (max-width:767px) {
      .e-con.e-flex {
        --width: 100%;
        --flex-wrap: var(--flex-wrap-mobile);
      }
    }

    .elementor-form-fields-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group>input,
    .elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group>textarea {
      flex-basis: 100%;
      max-width: 100%;
    }

    .elementor-field-group {
      flex-wrap: wrap;
      align-items: center;
    }

    .elementor-field-group.elementor-field-type-submit {
      align-items: flex-end;
    }

    .elementor-field-group .elementor-field-textual {
      width: 100%;
      max-width: 100%;
      border: 1px solid #69727d;
      background-color: transparent;
      color: #1f2124;
      vertical-align: middle;
      flex-grow: 1;
    }

    .elementor-field-group .elementor-field-textual:focus {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
      outline: 0;
    }

    .elementor-field-group .elementor-field-textual::placeholder {
      color: inherit;
      font-family: inherit;
      opacity: .6;
    }

    .elementor-field-textual {
      line-height: 1.4;
      font-size: 15px;
      min-height: 40px;
      padding: 5px 14px;
      border-radius: 3px;
    }

    .elementor-button-align-start .e-form__buttons,
    .elementor-button-align-start .elementor-field-type-submit {
      justify-content: flex-start;
    }

    .elementor-button-align-start .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
      flex-basis: auto;
    }

    .elementor-form .elementor-button {
      padding-block-start: 0;
      padding-block-end: 0;
      border: none;
    }

    .elementor-form .elementor-button>span {
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: 5px;
    }

    .elementor-form .elementor-button.elementor-size-sm {
      min-height: 40px;
    }

    .elementor-element .elementor-widget-container {
      transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
    }

    .elementor-heading-title {
      padding: 0;
      margin: 0;
      line-height: 1;
    }

    .elementor-button {
      display: inline-block;
      line-height: 1;
      background-color: #69727d;
      font-size: 15px;
      padding: 12px 24px;
      border-radius: 3px;
      color: #fff;
      fill: #fff;
      text-align: center;
      transition: all .3s;
    }

    .elementor-button:focus,
    .elementor-button:hover,
    .elementor-button:visited {
      color: #000;
    }

    .elementor-button-text {
      display: inline-block;
    }

    .elementor-button span {
      text-decoration: inherit;
    }
  }

  /*! CSS Used from: https://iqspark.org/wp-content/plugins/elementor/assets/css/conditionals/e-swiper.min.css?ver=3.24.5 ; media=all */
  @media all {
    .elementor-element {
      --swiper-theme-color: #000;
      --swiper-navigation-size: 44px;
      --swiper-pagination-bullet-size: 6px;
      --swiper-pagination-bullet-horizontal-gap: 6px;
    }
  }

  /*! CSS Used from: https://iqspark.org/wp-content/uploads/elementor/css/post-7.css?ver=1722170818 ; media=all */
  @media all {
    .e-con {
      --container-max-width: 1140px;
    }

    .elementor-widget:not(:last-child) {
      margin-block-end: 20px;
    }

    .elementor-element {
      --widgets-spacing: 20px 20px;
    }

    @media (max-width:1024px) {
      .e-con {
        --container-max-width: 1024px;
      }
    }

    @media (max-width:767px) {
      .e-con {
        --container-max-width: 767px;
      }
    }
  }

  /*! CSS Used from: https://iqspark.org/wp-content/plugins/pro-elements/assets/css/frontend.min.css?ver=3.21.2 ; media=all */
  @media all {
    .e-form__buttons {
      flex-wrap: wrap;
    }

    .e-form__buttons {
      display: flex;
    }

    .elementor-form .elementor-button>span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .elementor-form .elementor-button .elementor-button-text {
      white-space: normal;
      flex-grow: 0;
    }
  }

  /*! CSS Used from: https://iqspark.org/wp-content/uploads/elementor/css/global.css?ver=1722170819 ; media=all */
  @media all {
    .elementor-widget-heading .elementor-heading-title {
      color: var(--e-global-color-primary);
      font-family: var(--e-global-typography-primary-font-family), Sans-serif;
      font-weight: var(--e-global-typography-primary-font-weight);
    }

    .elementor-widget-text-editor {
      color: var(--e-global-color-text);
      font-family: var(--e-global-typography-text-font-family), Sans-serif;
      font-weight: var(--e-global-typography-text-font-weight);
    }

    .elementor-widget-form .elementor-field-group .elementor-field {
      color: var(--e-global-color-text);
    }

    .elementor-widget-form .elementor-field-group .elementor-field {
      font-family: var(--e-global-typography-text-font-family), Sans-serif;
      font-weight: var(--e-global-typography-text-font-weight);
    }

    .elementor-widget-form .elementor-button {
      font-family: var(--e-global-typography-accent-font-family), Sans-serif;
      font-weight: var(--e-global-typography-accent-font-weight);
    }

    .elementor-widget-form .elementor-button[type="submit"] {
      background-color: var(--e-global-color-accent);
    }

    .elementor-widget-form {
      --e-form-steps-indicator-inactive-primary-color: var(--e-global-color-text);
      --e-form-steps-indicator-active-primary-color: var(--e-global-color-accent);
      --e-form-steps-indicator-completed-primary-color: var(--e-global-color-accent);
      --e-form-steps-indicator-progress-color: var(--e-global-color-accent);
      --e-form-steps-indicator-progress-background-color: var(--e-global-color-text);
      --e-form-steps-indicator-progress-meter-color: var(--e-global-color-text);
    }
  }

  /*! CSS Used from: https://iqspark.org/wp-content/uploads/elementor/css/post-895.css?ver=1722225586 ; media=all */
  @media all {
    .elementor-895 .elementor-element.elementor-element-de51030 {
      --display: flex;
      --flex-direction: column;
      --container-widget-width: 100%;
      --container-widget-height: initial;
      --container-widget-flex-grow: 0;
      --container-widget-align-self: initial;
      --flex-wrap-mobile: wrap;
      --background-transition: 0.3s;
      --margin-top: 150px;
      --margin-bottom: 0px;
      --margin-left: 0px;
      --margin-right: 0px;
      --padding-top: 0px;
      --padding-bottom: 0px;
      --padding-left: 40px;
      --padding-right: 40px;
    }

    .elementor-895 .elementor-element.elementor-element-0886e87 {
      --display: flex;
      --background-transition: 0.3s;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      --padding-top: 40px;
      --padding-bottom: 40px;
      --padding-left: 50px;
      --padding-right: 50px;
    }

    .elementor-895 .elementor-element.elementor-element-2faa527 .elementor-heading-title {
      color: var(--e-global-color-secondary);
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 40px;
      font-weight: 600;
    }

    .elementor-895 .elementor-element.elementor-element-56d0118 {
      color: var(--e-global-color-text);
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 21px;
      font-weight: 400;
      line-height: 1px;
    }

    .elementor-895 .elementor-element.elementor-element-56d0118>.elementor-widget-container {
      margin: 5px 0px 0px 0px;
    }

    .elementor-895 .elementor-element.elementor-element-28bda67 .elementor-field-group {
      padding-right: calc(20px/2);
      padding-left: calc(20px/2);
      margin-bottom: 20px;
    }

    .elementor-895 .elementor-element.elementor-element-28bda67 .elementor-form-fields-wrapper {
      margin-left: calc(-20px/2);
      margin-right: calc(-20px/2);
      margin-bottom: -20px;
    }

    .elementor-895 .elementor-element.elementor-element-28bda67 .elementor-field-group .elementor-field {
      color: var(--e-global-color-text);
    }

    .elementor-895 .elementor-element.elementor-element-28bda67 .elementor-field-group .elementor-field {
      font-family: "Roboto", Sans-serif;
      font-size: 16px;
      font-weight: 400;
    }

    .elementor-895 .elementor-element.elementor-element-28bda67 .elementor-field-group:not(.elementor-field-type-upload) .elementor-field:not(.elementor-select-wrapper) {
      background-color: #FAFAFA;
      border-width: 1px 1px 1px 1px;
      border-radius: 8px 8px 8px 8px;
    }

    .elementor-895 .elementor-element.elementor-element-28bda67 .elementor-button {
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 22px;
      font-weight: 600;
      border-radius: 0px 15px 0px 15px;
      padding: 15px 30px 15px 30px;
    }

    .elementor-895 .elementor-element.elementor-element-28bda67 .elementor-button[type="submit"] {
      background-color: var(--e-global-color-d7f55a4);
      color: var(--e-global-color-astglobalcolor7);
    }

    .elementor-895 .elementor-element.elementor-element-28bda67 .elementor-button[type="submit"]:hover {
      color: #000;
    }

    .elementor-895 .elementor-element.elementor-element-28bda67 {
      --e-form-steps-indicators-spacing: 20px;
      --e-form-steps-indicator-padding: 30px;
      --e-form-steps-indicator-inactive-secondary-color: #ffffff;
      --e-form-steps-indicator-active-secondary-color: #ffffff;
      --e-form-steps-indicator-completed-secondary-color: #ffffff;
      --e-form-steps-divider-width: 1px;
      --e-form-steps-divider-gap: 10px;
    }

    @media (max-width:767px) {
      .elementor-895 .elementor-element.elementor-element-de51030 {
        --padding-top: 0px;
        --padding-bottom: 0px;
        --padding-left: 0px;
        --padding-right: 0px;
      }

      .elementor-895 .elementor-element.elementor-element-0886e87 {
        --padding-top: 30px;
        --padding-bottom: 50px;
        --padding-left: 30px;
        --padding-right: 30px;
      }

      .elementor-895 .elementor-element.elementor-element-56d0118 {
        line-height: 1em;
      }
    }
  }

  /*! CSS Used from: Embedded */
  @media (max-width:450px) {
    .ast-page-builder-template .entry-header {
      margin-top: 4rem !important;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .ast-page-builder-template .entry-header {
    margin-top: 6em;
  }

  /*! CSS Used fontfaces */
</style>